import React from "react"
import { graphql, Link } from "gatsby"
import { WindowLocation } from "@reach/router"
import { HomePageQuery } from "../../graphql-types"
import { Article } from "../components/Article"
import Layout from "../components/layout"

interface Props {
  location: WindowLocation
  data: HomePageQuery
}

const IndexPage: React.FC<Props> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const articles = data.allMdx.edges.map(
    ({
      node: {
        fields: { slug },
        frontmatter: { title, description, tags, date },
      },
    }) => ({
      title,
      description,
      tags,
      date,
      slug,
    })
  )

  return (
    <Layout location={location} title={siteTitle}>
      <main>
        <nav id="header">
          <h1>Quin Rainwater</h1>
          <p>
            Deconstructing full stack Typescript and sharing it with the world.
          </p>
          <hr />
          {/* <ul className="page-list">
            <li>Home</li>
            <li>All Posts</li>
            <li>About</li>
            <li>Tags</li>
          </ul> */}
        </nav>
        <section id="recent">
          <h2>Blog</h2>
          {articles.map(article => (
            <Article key={article.title} {...article} />
          ))}
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePage {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
