import React from "react"

interface Props {
  title: string
  description: string
  date: string
  tags: string[]
  slug: string
}

export const Article: React.FC<Props> = ({
  title,
  tags,
  date,
  description,
  slug,
}) => {
  return (
    <article>
      <p className="date">{date}</p>
      <div className="post">
        <h3>
          <a href={`/blog${slug}`}>{title}</a>
        </h3>
        <div className="seperator">&gt;&gt;</div>
        <summary>{description}</summary>
      </div>
      {/* <section className='tags'>
        {tags.map((tag) => (
          <div key={tag}>{tag}</div>
        ))}
      </section> */}
    </article>
  )
}
